/**
 * Prestapolis.
 * All Rights Reserved.
 */

/**
 * @author danielCastano
 * @file Unauthorized/index.js
 * @description Unauthorized container
 */

/** Dependencies */
import React, { useEffect, useState, createRef, useRef } from "react";

import approvedFull from "../img/approved.svg";
import "./styles.css";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { Button, Grid, TextField } from "@material-ui/core";
import ModalButtonPage from "../modal/ModalOneButton";

const Approved = ({data, documentNumber, fullName, email, telephone, valorMonto, cuotaInicial, tipoSolicitud, costoPlataforma, vsf, seguro, cuotas, meses, diaPago, interes, handleNext, nextValidation, setCuotaInicial, stateNextValidation, stateToken}) => {

  const formatNumber = (num) => {
		return new Intl.NumberFormat(["ban", "id"]).format(num);
	};

  const nombre = fullName.split(" ", 1);

  const inter = (Number(interes).toFixed(3));

  const dia = diaPago[8];

  const dia2 = diaPago[9];

  const dia3 = dia + dia2;

  const [isOpenModal, setIsOpenModal] = useState(null);

  useEffect(() => {
    /*if(stateToken === "0000") {
      nextValidation();
    }*/
    if(tipoSolicitud === 5){
      setCuotaInicial(0)
    }
  },[tipoSolicitud,setCuotaInicial]);

  const handleNextValidation = (e) => {
    /*if(stateNextValidation === true){
      handleNext();
    }
    if(stateNextValidation === false){
      setIsOpenModal(true);
    }*/
    handleNext();
  };

  const handleCloseModal = (e) => {
    setIsOpenModal(false);
    window.location.reload(true);
  }
  
  const  totalPago = (cuotas * (meses-1)) + cuotaInicial;

  const  totalPago2 = (cuotas * (meses)) + cuotaInicial + costoPlataforma;

  const refvalue = createRef();

  const [isContinuo, setIsContinuo] = useState(false);
  const [valor, setValor] = useState(null);

  const handleChange = (e) => {
    setValor(formatNumber((e.target.value).toString().replaceAll(".","").replaceAll(",","")));
  };

  const valorMontoString = (formatNumber((valorMonto).toString().replaceAll(".","").replaceAll(",","")));
  const cuotaInicialString = (formatNumber((cuotaInicial).toString().replaceAll(".","").replaceAll(",","")));

  useEffect(() => {
    const equivalenteValorMonto = valor === valorMontoString && cuotaInicialString === "0";
    const equivalenteCuotaInical = valor === cuotaInicialString && cuotaInicialString !== "0";
    setIsContinuo(equivalenteValorMonto || equivalenteCuotaInical);
  }, [valor, cuotaInicialString, valorMontoString, setIsContinuo]);

  return( 
    <>
    <div className="container-top">
        <ModalButtonPage
              isOpen={isOpenModal}
              openModal={handleCloseModal}
              message="Lo sentimos, tu solicitud ha sufrido cambios"
              eventButton={handleCloseModal}
              seconMessage="Tu solicitud ha sufrido cambios, por favor actualiza esta página, verifica y aprueba los nuevos valores sugeridos."
        />
        <div className="marginAproved">
        <div className="logoSad">
          <div className="logoCentralSad">
            <img  src={approvedFull} />{" "}
          </div>
        </div>
        <div className="textInicioApproved">
          <br></br>
          <strong>¡Felicitaciones!<br></br>{nombre}</strong>
        </div>
        <br></br>
        <div style={{marginTop: "-25pt"}} className="textContenidoApproved">
          <p><strong>Solicitud Preaprobada</strong></p>
        </div>
        <div className="clausulasApproved">
        <br></br>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">   
            <strong>    
              -Número de cédula:
            </strong> 
          </div>
          <div className="textClausulasApproved2">        
            {documentNumber}
          </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">   
            <strong>       
              -Nombre:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {fullName}
          </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">  
            <strong>   
              -Email:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {email}
          </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved"> 
            <strong>      
              -Número de Celular:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {telephone}
          </div>
          </div>
          <br></br>
        </div>
        <div className="textContenidoApproved">
          <p><strong>Información de crédito</strong></p>
        </div>
        <div className="clausulasApproved">
        <br></br>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">
            <strong>        
              -Monto preaprobado:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {`$${formatNumber(Math.floor(valorMonto))}`}
          </div>
          </div>
          {cuotaInicial !== 0?
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved"> 
            <strong>      
              -Cuota inicial:
            </strong>
          </div>       
          <div className="textClausulasApproved2">  
          {`$${formatNumber(Math.floor(cuotaInicial))}`}      
          </div>
          </div>
          : null}
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">   
            <strong>    
              -Costo de plataforma:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {`$${formatNumber(Math.floor(costoPlataforma))} por cuotas`}
          </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">  
            <strong>     
              -Forma de pago:
            </strong>
          </div>
          {tipoSolicitud === 1?
          <div className="textClausulasApproved2">  
          {`$${formatNumber(Math.floor(cuotas))} por ${meses -1} meses`}      
          </div>
          :<div className="textClausulasApproved2">  
          {`$${formatNumber(Math.floor(cuotas))} por ${meses} meses`}      
          </div>}
          </div>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">  
            <strong>     
              -Día de pago:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {`${dia3} de cada mes`}
          </div>
          </div>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">  
            <strong>     
              -Tasa de interés:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
            {`${inter} % mes vencido `}
          </div>
          </div>

          {/*Campo VSF*/}
          <div hidden={vsf === null ? true : false}>
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">  
            <strong>     
              -Fianza FGA +IVA:
            </strong>
          </div>
          <div className="textClausulasApproved2">  
          {`$${formatNumber(Math.floor(vsf === null ? "-" : vsf))} por cuotas`}         
          </div>
          </div>
          
          {/*Campo Seguro*/}
          <div style={{display:"flex", justifyContent:"space-between", marginRight: "10pt"}}>
          <div className="textClausulasApproved">  
            <strong>     
              -Seguros:
            </strong>
          </div>
          <div className="textClausulasApproved2">        
          {`$${formatNumber(Math.floor(data.seguro === null ? "-" : data.seguro))}`}
          </div>
          </div>
          </div>

          {cuotaInicial !== 0?
          <div style={{marginTop: "10pt", fontSize:"15pt"}} className="textContenidoApproved2">        
            <strong>Total a pagar ${formatNumber(Math.floor(totalPago))}</strong>
          </div>
          : <div style={{marginTop: "10pt", fontSize:"15pt"}} className="textContenidoApproved2">        
          <strong>Total a pagar ${formatNumber(Math.floor(totalPago2))}</strong>
        </div>}
          <br></br>
        </div>
        <div className="textMiddleApproved">
          <p>*El pago de la fianza y costo de plataforma se pagan mensual sobre cada cuota pagada.</p>
        </div>
        
        {cuotaInicial !== 0 ?
        <div>
        <div style={{textAlign: "center"}} className="textContenidoApproved">
          <strong>VALOR PRIMER PAGO</strong>
        </div>
        <div style={{textAlign: "center"}} className="textContenidoApproved">
          <strong>${formatNumber(Math.floor(cuotaInicial))}</strong>
        </div>
        </div>: 
        <div>
        <div style={{textAlign: "center"}} className="textContenidoApproved">
          <strong>VALOR MONTO APROBADO</strong>
        </div>
        <div style={{textAlign: "center"}} className="textContenidoApproved">
          <strong>${formatNumber(Math.floor(valorMonto))}</strong>
        </div>
        </div>}
        
        <div className="clausulas">
        <br></br>
          <p className="textClausulas2">       
          Falta poco para aprobar tu solicitud de crédito, por favor VALIDA la siguiente información:
          <br></br>
          * fecha de pago, valor de tu cuota y valor del producto de tu solicitud
          <br></br>
          * Confirma el valor de tu abono en caso de ser requerido, continua y firma el pagaré electrónico.
          </p>
          <br></br>
        </div>
        <br></br>
        {cuotaInicial !== 0 ?
        <div style={{textAlign: "center", marginTop: "-15pt"}} className="textContenidoApproved">
          <strong>Valida el valor de tu cuota inicial</strong>
        </div>:
        <div style={{textAlign: "center", marginTop: "-15pt"}} className="textContenidoApproved">
          <strong>Valida el valor del monto aprobado</strong>
        </div>
        }
        <br></br>
        <Grid container spacing={1} alignItems="flex-end" className="input-person" id="input-select" >
          <Grid  item md={true} sm={true} xs={true} >
            <TextField
              required
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                className: "label__color",
                inputComponent: formatNumber,
              }}
              placeholder="800.000"
              label="Valor"
              fullWidth
              name="valueRequiredAmount"
              type="tel"
              id="input-select"
              color="primary"
              mask={"000.000.000"}
              inputRef={refvalue}
              value={valor}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        
      
        <div>
        <Button
          disabled={!isContinuo}
          variant="contained"
          style={{ textTransform: "none", width: "14rem", borderRadius: "100px", background: "#2BAEB3", color: "#ffffff" }}
          className="MuiTypography-colorPrimary"
          onClick={(e) => handleNextValidation()}
        >
          <strong>
            Continuar
          </strong>
        </Button>
        </div>
        <br></br>
        </div>
        </div>
        </>
  )};

export default Approved;