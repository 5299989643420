import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import "./CreatedStyles.css";
import PersonDataSection from "./PersonData";
import logoprestapolis from "../img/logoprestapolis.png";
import logoIC from "../img/logoIC.svg";
import logo_prestapolis_color from "../img/logo_prestapolis_color.png";

import ContactDataSection from "./ContactData";
import EconomicDataSection from "./EconomicData";
import ModalPage from "../modal/Modal";
import ModalStatus from "../modal/modalStatus";
import ModalButtonPage from "../modal/ModalOneButton";
import NotRegistered from "../Rejected";
import NewOptions from "../Options";
import Used from "../Used";
import GenericMessage from "../genericMessageError/genericMessag";

import {
  sendRequest,
  getCommerceByName,
  getParameters,
  crearSolicitud,
  getInfoByToken,
  getSucursalByName,
} from "../../../../infractructura/ApiPrestapolisPublic";
import { useParams } from "react-router-dom";
import { PersonAdd } from "@material-ui/icons";
import { HelpValidate } from "../../../../domain/help/HelpValidate";
import { height } from "@mui/system";

const style = {
  color: {
    backgroundColor: "red",
  },
};

export default function Solicitud() {
  const { code, token } = useParams();


  const [activeSection, setActiveSection] = useState(1);
  const [lParam, setLParam] = useState([]);
  const [commerceData, setCommerceData] = useState({});
  const [info, setInfo] = useState("");
  const [idComerce, setidComerce] = useState("")
  const [idVendedor, setidVendedor] = useState("")
  const [passDirect, setPassDirect] = useState(false);
  const [sucursalId, setsucursalId] = useState("")
  const [personData, setPersonData] = useState({
    names: "",
    firstSurname: "",
    secondSurname: "",
    idType: "CC",
    idNumber: "",
    occupation: "",
    monthlyIncomeRange: "",
    economicActivity: "",
    email: "",
    cellPhone: "",
    acceptPolitics: false,
    authorizeConsultation: false,
    birthDate: "",
    issuerDate: "",
  });

  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");
  const [datosAdd, setdatosAdd] = useState({
    frontSideDocument: "",
    backSideDocument: "",
    occupation: "",
    monthlyIncomeRange: "",
    monthlyExpenses: "",
    fotoSelfie: "",
    detalle:"",
    contacto:"",
    datoContacto:"",
    padrino:""
  });


  const [contacts, setContacts] = useState({
    contact: [
      {
        nomenclature: "",
        addressNumber1: "",
        addressNumber2: "",
        city: "",
        zone: "",
        address: "",
        cellPhone: "",
        email: "",
        indications: "",
      },
    ],
  });

  const [lsZona, setlsZona] = useState([]);

  const [idNumber, setidNumber] = useState("");
  const [nombrePersona, setNombrePersona] = useState([null]);

  const [idSolicitud, setIdSolicitud] = useState(null);

  const [persona, setPersona] = useState({ firstName: "", firstLastName: "", secondLastName: "", birthDate: "" });
  const [contact, setContact] = useState({});

  const [nombres, setNombres] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [fechaNacimineto, setFechaNacimiento] = useState("");
  const [fechaExpedicion, setFechaExpedicion] = useState("");
  const [celular, setCelular] = useState("");
  const [correo, setCorreo] = useState("");

  const [newCuota, setNewCuota] = useState(null);
  const [newValor, setNewValor] = useState(null);

  const [morasVigentes, setMorasVigentes] = useState(null);
  const [primerCredito, setPrimerCredito] = useState(null);

  const [valorMonto, setValorMonto] = useState(null);
  const [valorinicial, setValorInicial] = useState(null);

  const [checkCredito, setCheckCredito] = React.useState(null);
  const [checkMoras, setCheckMoras] = React.useState(null);

  const [stateCard, setStateCard] = useState("selected")
  const [stateCard2, setStateCard2] = useState("disabled")

  const [request, setRequest] = useState({
    requests: [
      {
        valueRequiredAmount: "",
        descripcion: "",
        link: "",
        acceptPolitics: false,
        authorizeConsultation: false,
        loanTerm: 0,
        vsf:0,
        costoPlataforma: 0,
        abonoInicial: "",
        plans: [],
        selected: false,
        interes: 0,
        minimo: 0,
        maximo: 0,
        idCommerce: 1,
        birthDate: "",
        selectProducto: [],
        valorAbono: "",
        primer_credito: primerCredito,
        moras_vigentes: morasVigentes,
      },
    ],
  });

  const [estado, setEstado] = useState({
    disabled: false,
    error: false,
    showLoad: false,
    isOpenModal: false,
    disabledButton: false,
    message: "",
    code: "",
    parameters: [],
    comercio: [],
    commerceData:"",
  });

  const [isFinal, setIsFinal] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [isProductoIn, setisProductoIn] = useState(false);
  const [buttonIn, setbuttonIn] = useState(false);
  const [isNewPro, setisNewPro] = useState("");
  const [planes, setPlanes] = useState([]);
  const [servicesOk, setServicesOk] = useState(false);
  const [planSelect, setplanSelect] = useState([]);
  const [disContinuo, setdisContinuo] = useState(true);
  const [isCheck, setIscheck] = useState(false);

  const [valor, setValor] = useState(null);
  const [datoContacto, setDatoContacto] = useState("");
  const [detalle, setDetalle] = useState(null);
  const [key, setKey] = useState(null);
  const [documento, setDocumento] = useState(null);

  const [economicData, setEconomicData] = useState(null);

  const [capacidadMedia, setCapacidadMedia] = useState(null);
  const [capacidadMinima, setCapacidadMinima] = useState(null);

  const [codBandera, setCodBandera] = useState(null);
  const valorCapacidadMinima = 600000;
  const valorCapacidadMedia = 900000;

  const [montoMaximo, setMontoMaximo] = useState(null);
  const [montoMinimo, setMontoMinimo] = useState(null);
  const [logo, setLogo] = useState("");
  const [baas, setBaas] = useState("");

  //Modal states
  const [isOpenModal, setisOpenModal] = useState(false);
  const [isContinuo, setIsContinuo] = useState(false);
  const [messageModal, setmessageModal] = useState("")
  const [secondMessage, setsecondMessage] = useState("")
  const [typeModal, seTypeModal] = useState("")
  const [disabledErr, setdisabledErr] = useState(false);
  const [newComercio, setNewComercio] = useState(false);
  


  useEffect(async () => {
    await loadParameters();
    getDateSucursalByApikey();
  }, []);


  const handledOpenModal = (message, seconMessage, type) => {
    seTypeModal(type)
    setisOpenModal(!isOpenModal);
    setmessageModal(message);
    setsecondMessage(seconMessage);
  }

  // const goToHome = () => {
 
  //   setTimeout(() => {
  //     window.location.replace("https://www.prestapolis.com/");
  //   },1);
  // };

  // const handledModal = () => {
  //   setisOpenModal(!isOpenModal)
  // };



  const rechValorPro = (valor) => {
    setRequest({
      requests: [
        {
          valueRequiredAmount: valor,
        },
      ],
    });
  };

  
  const validateDate = (date) => {
    if (date !== undefined && date !== null) {
      return true
    } else {
      return false
    }
  }

  const handleToggleErrorClick = () => {
    setError(true);
  };

useEffect(async () => {
    
  });

  const loadData = (code ) => {
   
    setEstado({ showLoad: true });
    getCommerceByName(code)
      .then((response) => {
        
        let info = response.respuesta;
        if (response.codigoRespuesta === "0000") {
          setMontoMaximo(info.montoMaximo);
          setMontoMinimo(info.montoMinimo);
          setLogo(info.logo);
          setBaas(info.baas);
          setEstado({ showLoad: false });
          setCommerceData(info);
          if(info.baas == true){
            setNewComercio(true) 
          }
    
          setidComerce(info.id)
          setServicesOk(true);
          
          if (info.tipoFormulario === null || info.tipoFormulario === undefined) {
            setError(true);
            setMessage("Este comercio no contienen los elementos necesarios para llevar acabo tu crédito, por favor comunícate con el administrador");
          }
         
         
        } else {
          setEstado({ showLoad: false });
          setError(true);
          setMessage(response.mensaje);
        }
       
      })
      .catch((error) => {
        setEstado({ showLoad: false });
        setEstado({ error: true });
   
      });

  };
  

  const getDateSucursalByApikey = async () => {
  
    getSucursalByName(code).then((response) => {
      const info = response.respuesta
     
      if(response.respuesta.comercioBean == null){
        loadData(code)
       
      }else{

        const comerSucur = {...info, 
          tipoFormulario :info.tipoFormulario? info.tipoFormulario: info.comercioBean.tipoFormulario,
          baseDivisorAbono: info.baseDivisorAbono?info.baseDivisorAbono:info.comercioBean.baseDivisorAbono,
          montoMinimo: info.montoMinimo? info.montoMinimo:info.comercioBean.montoMinimo,
          montoMaximo: info.montoMaximo? info.montoMaximo:info.comercioBean.montoMaximo,
          estructuraCuotas: info.estructuraCuotas? info.estructuraCuotas:info.comercioBean.estructuraCuotas,

          comision: info.comision? info.comision:info.comercioBean.comision,
          logo: info.logo? info.logo:info.comercioBean.logo,
          baas: info.baas? info.baas:info.comercioBean.baas,
          valorMaximoIVA: info.valorMaximoIVA? info.valorMaximoIVA:info.comercioBean.valorMaximoIVA,
          valorMinimoIVA: info.valorMinimoIVA? info.valorMinimoIVA:info.comercioBean.valorMinimoIVA,
          exentoIVA: info.exentoIVA? info.exentoIVA:info.comercioBean.exentoIVA,
          confianza: info.confianza? info.confianza:info.comercioBean.confianza,
         
        };

      
        setMontoMaximo(info.montoMaximo);
        setMontoMinimo(info.montoMinimo);
        setEstado({ showLoad: false });
        setCommerceData(comerSucur);
        setsucursalId(info.id)
        setidComerce(response.respuesta.comercioBean.id)
        setServicesOk(true);

      }
    
    }).catch((err) => {
    
      setError(true);
      setMessage(` mensaje desde api sucursal ha ocurrido un error interno comunícate con soporte el código de error es: (${err.status})`);
    })

  }

  const loadParameters = () => {
    getParameters(0, 50, "")
      .then((response) => {
       
        if (response.code === "0000") {
          setLParam(response.content);
        } else {
          setEstado({ message: response.mensaje });
          setEstado({ error: true });
        }
      })
      .catch((error) => {
        setEstado({ showLoad: false });
        setEstado({ error: true });
       
      });
  };

  const handleDataPerson = (data) => {
  

    const dataPerson = {
      names: data.names,
      firstSurname: data.firstSurname,
      secondSurname: data.secondSurname,
      fullName: `${data.names} ${data.firstSurname} ${data.secondSurname}`,
      idNumber: data.idNumber,
      email: data.email,
      cellPhone: data.cellPhone,
      acceptPolitics: data.acceptPolitics,
      authorizeConsultation: data.authorizeConsultation,
      birthDate: data.birthDate,
      issuerDate: data.issuerDate,
    };

    const cell = data.cellPhone;
    const email = data.email;

    setEmail(email);
    setCellphone(cell);
    setidNumber(dataPerson.idNumber);
    setPersonData(dataPerson);
    setNombrePersona(data.names)
  };

  const handleEconomicData = (data) => {

    const producto = data.producto;
    let arrayProducto = [];
    let productoString = "";
   

    if (typeof producto === "object" || typeof producto === "Array") {
      producto.forEach((element) => {
        arrayProducto.push(element.label);
      });

      productoString = arrayProducto.toString();
    } else {
      productoString = producto;
    }

    const request = {
      requests: [
        {
          valueRequiredAmount: data.valueRequiredAmount,
          descripcion: productoString,
          loanTerm: data.loanTerm,
          vsf:data.vsf,
          costoPlataforma: data.costoPlataforma,
          abonoInicial: data.abonoInicial,
          plans: data.plans,
          selected: data.selected,
          interes: data.interes,
          minimo: data.minimo,
          maximo: data.maximo,
          catalogo: data.catalogo,
          acceptPolitics: personData.acceptPolitics,
          authorizeConsultation: personData.authorizeConsultation,
          selectProducto: producto,
          minAbono: data.minAbono,
          primer_credito: primerCredito,
          moras_vigentes: morasVigentes,

        },
      ],
    };

    setRequest(request);
  };

  const handleContact = (data, messageFront, messageBack, messageSelfie, isBack) => {
  
    const datosPersona = {
      frontSideDocument: data.frontSideDocument,
      backSideDocument: data.backSideDocument,
      fotoSelfie: data.fotoSelfie,
      occupation: data.occupation,
      monthlyIncomeRange: data.monthlyIncomeRange,
      monthlyExpenses: data.monthlyExpenses,
      frontSideDocument: messageFront,
      backSideDocument: messageBack,
      fotoSelfie: messageSelfie,
      datoContacto:data.datoContacto, 
      detalle:data.padrino + " - " + data.nombreCompleto, 
      key:"PADRINO", 
      documento:personData.idNumber, 
    };

    const contacts = {
      contact: [
        {
          nomenclature: data.nomenclature,
          addressNumber1: data.addressNumber1,
          addressNumber2: data.addressNumber2,
          address: `${data.nomenclature}-${data.addressNumber1}-${data.addressNumber2}`,
          zone: data.departamento,
          city: data.municipio,
          cellPhone: data.cellPhone,
          email: data.email,
          indications: data.indications,
        },
      ],
    };

    setdatosAdd(datosPersona);
    setContacts(contacts);

    if (isBack) {
      if (passDirect === true) {
        handleUltimatePrev();
      } else {
        handlePrev();
      }
    } else {
      setIsFinal(true);
    }
  };

  useEffect(async () => {
   
    
    if (isFinal && activeSection == 4) {
      handleSendRequest();
    }
    getDateSucursalByApikey()

    

    await loadParameters();

    // handledSetValues();

    if (token) {
			const dataSolicitud =  await getInfoByToken(token)

      if (dataSolicitud.codigoRespuesta === "0000") {
        if (dataSolicitud.respuesta.estadoSolicitud.estado === "Prevalidacion" && 
        dataSolicitud.respuesta.estadoSolicitud.id === "12" ) {
          setAlert(true);
        }else{
          setAlert(false)
        }
			}
		} 

    setIsFinal(false);
  }, [activeSection, isFinal]);


  const handleActiveSection = () => {
    switch (activeSection) {
      case 1:
        return (
          <div>
            <EconomicDataSection
              commerceData={commerceData}
              param={lParam}
              estado={estado}
              setRequest={setRequest}
              handleNext={handleNext}
              handlePrev={handlePrev}
              handleEconomicData={handleEconomicData}
              active={activeSection}
              request={request.requests[0]}
              rechValorPro={rechValorPro}
              isProductoIn={true}
              setisProductoIn={setisProductoIn}
              buttonIn={buttonIn}
              setbuttonIn={setbuttonIn}
              setPlanes={setPlanes}
              planes={planes}
              isNewPro={isNewPro}
              setisNewPro={setisNewPro}
              planSelect={planSelect}
              setplanSelect={setplanSelect}
              setdisContinuo={setdisContinuo}
              disContinuo={disContinuo}
              setIscheck={setIscheck}
              isCheck={isCheck}

              setMorasVigentes={setMorasVigentes}
              setPrimerCredito={setPrimerCredito}
              primerCredito={primerCredito}
              morasVigentes={morasVigentes}
              setCheckCredito={setCheckCredito}
              setCheckMoras={setCheckMoras}
              checkCredito={checkCredito}
              checkMoras={checkMoras}

              setValorMonto={setValorMonto}
              setValorInicial={setValorInicial}

              token={token}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <PersonDataSection
              estado={estado}
              handleData={handleDataPerson}
              handleNext={handleNext}
              disabled={estado.disabled}
              active={activeSection}
              personData={personData}
              handlePrev={handlePrev}

              setIdSolicitud={setIdSolicitud}
              idSolicitud={idSolicitud}
              setPersona={setPersona}
              setContact={setContact}
              setNombres={setNombres}
              setPrimerApellido={setPrimerApellido}
              setSegundoApellido={setSegundoApellido}
              setFechaNacimiento={setFechaNacimiento}
              setFechaExpedicion={setFechaExpedicion}
              setCelular={setCelular}
              setCorreo={setCorreo}

              // token para rescatar datos
              token={token}

              // id de sucursal cuando el formulario viene de sucursal
              setsucursalId={setsucursalId}
              sucursalId={sucursalId}
              idComerce={idComerce}
              setidVendedor={setidVendedor}
              idVendedor={idVendedor}

              commerceData={commerceData}
              planSelect={planSelect}
              request={request}
              handleReject={handleReject}

              persona={persona}
              contact={contact}
              nombres={nombres}
              primerApellido={primerApellido}
              segundoApellido={segundoApellido}
              fechaNacimineto={fechaNacimineto}
              fechaExpedicion={fechaExpedicion}
              celular={celular}
              correo={correo}

              primerCredito={primerCredito}
              morasVigentes={morasVigentes}
              handleUltimateStep={handleUltimateStep}
              handelMessageGeneric={handelMessageGeneric}

              setCodBandera={setCodBandera}
              codBandera={codBandera}
              capacidadMedia={capacidadMedia}
              setCapacidadMedia={setCapacidadMedia}
              capacidadMinima={capacidadMinima}
              setCapacidadMinima={setCapacidadMinima}

              valorMonto={valorMonto}
              valorinicial={valorinicial}

              handleUsed={handleUsed}
              setPassDirect={setPassDirect}
            />
          </div>
        );

      case 3:
        return (
          <NewOptions
            commerceData={commerceData}
            param={lParam}
            estado={estado}
            setRequest={setRequest}
            handleNext={handleNext}
            handlePrev={handlePrev}
            handleEconomicData={handleEconomicData}
            active={activeSection}
            request={request.requests[0]}
            rechValorPro={rechValorPro}
            isProductoIn={true}
            setisProductoIn={setisProductoIn}
            buttonIn={buttonIn}
            setbuttonIn={setbuttonIn}
            setPlanes={setPlanes}
            planes={planes}
            isNewPro={isNewPro}
            setisNewPro={setisNewPro}
            planSelect={planSelect}
            setplanSelect={setplanSelect}
            setdisContinuo={setdisContinuo}
            disContinuo={disContinuo}

            setMorasVigentes={setMorasVigentes}
            setPrimerCredito={setPrimerCredito}

            setNewCuota={setNewCuota}
            setNewValor={setNewValor}
            newValor={newValor}
            newCuota={newCuota}

            codBandera={codBandera}
            stateCard={stateCard}
            setStateCard={setStateCard}
            stateCard2={stateCard2}
            setStateCard2={setStateCard2}
            valorCapacidadMinima={valorCapacidadMinima}
            valorCapacidadMedia={valorCapacidadMedia}

            valorMonto={valorMonto}
            valorinicial={valorinicial}
            nombres={nombres}
            montoMaximo={montoMaximo}
            montoMinimo={montoMinimo}
            logo={logo}
            baas={baas}
          />
        );

      case 4:
        return (
          <div>
            <ContactDataSection
              active={activeSection}
              handleNext={handleSendRequest}
              handlePrev={handlePrev}
              sendData={handleContact}
              disabled={estado.disabled}
              disabledButton={estado.disabledButton}
              datosAdd={datosAdd}
              idNumber={idNumber}


              // Datos para cachear que el servicio se consuma antes de que aparezca
              //La alerta 
              isOpenModal={isOpenModal}
              messageModal={messageModal}
              secondMessage={secondMessage}
              type={typeModal}
              setisOpenModal={setisOpenModal}
              setIsContinuo={setIsContinuo}
              isContinuo={isContinuo}
              disabledErr={disabledErr}
              // 


              idSolicitud={idSolicitud}
              persona={persona}
              contact={contact}
              contacts={contacts}
              nombres={nombres}
              primerApellido={primerApellido}
              segundoApellido={segundoApellido}
              fechaNacimineto={fechaNacimineto}
              celular={celular}
              correo={correo}

              primerCredito={primerCredito}
              morasVigentes={morasVigentes}
              setValor={setValor}

              setDatoContacto={setDatoContacto}
              datoContacto={datoContacto}
              
              detalle={detalle}
              setDetalle={setDetalle} 

              contactoPadrino={setDatoContacto}
              
              key={setKey} 
              documento={setDocumento} 

              valor={valor}
              handleUltimatePrev={handleUltimatePrev}
              lsZona={lsZona}
              setlsZona={setlsZona}
            />
          </div>
        );
      case -1:
        return (
          <div>
            <NotRegistered
              nombres={nombres}
            />
          </div>
        );
      case -2:
        return (
          <div>
            <Used
              nombres={nombres}
            />
          </div>
        )
      case -3:
        return (
          <div>
            <GenericMessage
              nombres={nombres}
              messageOne={"Ya tiene una solicitud con otros datos de contacto"}
              messageTow={"consulta con tu asesor de ventas para que valide tus datos"}

            />
          </div>
        )
      default:
        return <div></div>;
    }
  };

  const handleNext = (e) => {
    setActiveSection(activeSection + 1);
  };

  const handlePrev = (e) => {
    setActiveSection(activeSection - 1);
  };

  const handleReject = (e) => {
    setActiveSection(-1);
  };

  const handleUsed = (e) => {
    setActiveSection(-2);
  };

  const handelMessageGeneric = () => {
    setActiveSection(-3)
  }

  const handleUltimateStep = (e) => {
    setActiveSection(activeSection + 2);
  };

  const handleUltimatePrev = (e) => {
    setActiveSection(activeSection - 2);
  };

  const handleSendRequest = () => {

    setEstado({
      showLoad: true,
      disabledButton: true,
    });

    setIsContinuo(true)

    let valueRequiredAmount = request.requests[0].valueRequiredAmount;
    if (typeof valueRequiredAmount === "string") {
      valueRequiredAmount = valueRequiredAmount.includes(".")
        ? valueRequiredAmount.split(".")
        : valueRequiredAmount.split(",");
      valueRequiredAmount = valueRequiredAmount.join("");
      valueRequiredAmount = parseInt(valueRequiredAmount, 10);
    }

    let abono = request.requests[0].abonoInicial;
    if (typeof abono === "string") {
      abono = abono.includes(".") ? abono.split(".") : abono.split(",");
      abono = abono.join("");
      abono = parseInt(abono, 10);
    }

    let doc = idNumber;
    if (typeof doc === "string") {
      doc = doc.includes(".") ? doc.split(".") : doc.split(",");
      doc = doc.join("");
      doc = parseInt(doc, 10);
    }

    let idTipo = abono === 0 ? 5 : 1;

    let newComercio =  commerceData.apikey == process.env.REACT_APP_KEY_COMERCE ? process.env.REACT_APP_ID_INTERES : request.requests[0].interes.id

    let data = {
      producto: request.requests[0].descripcion == "" ? "Libre inversion" : request.requests[0].descripcion,
      interes: {
        id: newComercio,
      },
      valormontorequerido: newValor === null ? parseInt(valueRequiredAmount, 10) : newValor,
      aceptopolitica: personData.acceptPolitics,
      autorizoconsulta: personData.authorizeConsultation,
      plazosolicitado: request.requests[0].loanTerm,
      costoPlataforma: request.requests[0].costoPlataforma,
      linkproducto: datosAdd.fotoSelfie,
      datosPersona: {
        birthDate: fechaNacimineto,
        issuerDate: fechaExpedicion,
        documentNumber: personData.idNumber,
        documentType: personData.idType == "" ? "CC" : "CC",
        nombrecompleto: `${nombres} ${primerApellido} ${segundoApellido}`,
        firstLastName: primerApellido,
        firstName: nombres,
        secondLastName: segundoApellido,
        ladofrontaldocumento: datosAdd.frontSideDocument,
        ladoposteriordocumento: datosAdd.backSideDocument,
        ocupacion: datosAdd.occupation,
        rangoingresomensual: datosAdd.monthlyIncomeRange,
        contactos: [
          {
            nomenclature: contacts.nomenclature,
            addressNumber1: contacts.addressNumber1,
            addressNumber2: contacts.addressNumber2,
            cellphone: celular,
            address: null,
            email: correo,
            byDefault: false,
            indications: contacts.contact[0].indications,
            city: contacts.contact[0].city,
            zone: contacts.contact[0].zone,
          },
         
        ],
        
        primer_credito: primerCredito,
        moras_vigentes: morasVigentes,
        gastos_mensuales: parseInt(valor.toString().replaceAll(".", "").replaceAll(",", "")),
      },
      
      contactoAdicional:
         { 
        datoContacto:datosAdd.datoContacto, 
        detalle:datosAdd.detalle, 
        key:"PADRINO", 
        documento:personData.idNumber, 
      },

      tipoSolicitud: {
        id: idTipo,
      },
      sucursal: {
        id: code.startsWith("S") || token? sucursalId: 1,
      },
      comercio: {
        id: idComerce,
      },
      abono: newCuota === null ? abono : newCuota,
      vendedor: {
        id: token? idVendedor:1,
      },
      comision: planSelect.commission,
      porcentajeVsf:commerceData.porcentaje_vsf, 
      vsf:request.requests[0].vsf, 
      seguro:0, 
      id: idSolicitud,
    };

    crearSolicitud(data)
    
      .then((response) => {
        if (
          response.codigoRespuesta === "0000" ||
          response.codigoRespuesta === "DT009"
        ) {
          setEstado({
            showLoad: false,
            isOpenModal: true,
          });

          setIsContinuo(false)

          handledOpenModal(
            "Felicitaciones, tu solicitud ha sido enviada con éxito",
            "Tu solicitud va a ser analizada y sujeta a una respuesta, la cual será enviada a tu correo electrónico y/o al vendedor que te está ayudando con la compra, para que puedas continuar con el proceso de aprobación del crédito.",
            "succes"
          )
          setdisabledErr(true)

        } else {
          setEstado({
            showLoad: false,
            error: true,
            message: response.mensaje,
            disabledButton: false,
          });

          setIsContinuo(false)
          setdisabledErr(false)
          handledOpenModal(
            `Ha ocurrido un error ${response.codigoRespuesta}`,
            "Tu solicitud no ha podido ser enviada por favor comunícate con soporte",
            "error"
          )
        }
     
      })
      .catch((err) => {
        setEstado({
          showLoad: false,
          disabledButton: false,
        });
      
        setIsContinuo(false)

        setdisabledErr(false)
        handledOpenModal(
          `Ha ocurrido un error ${err}`,
          err,
          "error"
        )
      });
  };
  

  return (
    <>
      <div className="container-top">
        <ModalPage
          isOpen={error}
          openModal={handleToggleErrorClick}
          message="Estas a un paso de acceder a la comunidad Prestapolis."
          seconMessage={message}
        />
        <ModalStatus
          isOpen={alert}
          message="Estas a un paso de acceder a la comunidad Prestapolis."
          seconMessage={message}
        />
        <div className={process.env.REACT_APP_BOX_PRESTAPOLIS}></div>
        <div className="container-text">
          <div style={{paddingTop: 15}} className="logo">
          {commerceData.logo && 
            <div style={{ marginRight: "60px", marginTop: "-10px", marginBottom: "-50px" }}>
              <a
                href="https://www.prestapolis.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <br></br>
                
                <img alt="Logo" id="logo-prestapolis" 
                style={{ 
                  position: "relative",
                  //width: "250px",
                  //height: "239px",
                  margin: 2,
                  marginTop: "10px",
                  marginLeft: 40    
                 }}
                 
                 src={
                   commerceData.logo  !== null || commerceData.logo  == " " ? "" || `https://${process.env.REACT_APP_AWS_BUCKET}/${commerceData.logo}` : `https://s3.amazonaws.com/cdn.prestapolis.com/logos/LOGO+FINAL_LOGO+CON+ISOTIPO.png`        
                  } />
             
              </a>
            </div>   
            }
            {estado.showLoad && (
              <div className="Loading justify-content-center">
                <ReactLoading
                  type={"cylon"}
                  color={"#000"}
                  height={50}
                  width={50}
                />
              </div>
            )}
          </div>

          <div style={{paddingTop: 15, marginTop: "30px"}}  className="text-white"><b>Inicia tu solicitud de crédito</b></div>

          <div className="progressbar">
            <div className={activeSection > 0 ? "active" : "inactive"}>1</div>
            <div className={activeSection > 1 ? "active" : "inactive"}>2</div>
            <div className={activeSection > 2 ? "active" : "inactive"}>3</div>
            <div className={activeSection > 3 ? "active" : "inactive"}>4</div>
          </div>

          {servicesOk && (
            <div className="col">
              {<div className="form-confirm">{handleActiveSection()}</div>}
            </div>
          )}
        </div>
        
         {!newComercio ?   (
          
          <div className="col-12 footer">
          <div className="info">
            <p>
              <strong>Evite ser victima de fraudes financieros</strong>
              <br></br>
              Conoce las recomendaciones de la SuperIntendencia Financiera
              haciendo click{" "}
              <a
                href="https://www.superfinanciera.gov.co/inicio/sala-de-prensa/advertencias-y-medidas-administrativas-por-ejercicio-ilegal-de-actividad-financiera/advertencias-por-suplantacion--10097510"
                rel="noopener noreferrer"
                target="_blank"
                className="links"
              >
                aquí
              </a>

              <br></br>
              &copy;2022 PrestaPolis S.A.S. todos los derechos reservados{" "}
              {`${process.env.REACT_APP_VERSION}`}{" "}
              
              <br></br>
              PRESTAPOLIS SAS de Colombia{" "}
              <a
                href="mailto:ayuda@prestapolis.com"
                rel="noopener noreferrer"
                target="_blank"
                className="links"
              >
                ayuda@prestapolis.com{" "}
              </a>{" "}
              NIT 901035049-1
              <a
                href="https://www.sic.gov.co/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <br></br>
                <img
                  className="logo2"
                  alt="Logo"
                  id="industriaycomerio"
                  src={logoIC}
                />{" "}
              </a>

            </p>
          </div>
        </div>
         ):(
         <div className="col-12 footer">
         <div className="info">
           <p>
             <strong>Evite ser victima de fraudes financieros</strong>
             <br></br>
             Conoce las recomendaciones de la SuperIntendencia Financiera
             haciendo click{" "}
             <a
               href="https://www.superfinanciera.gov.co/inicio/sala-de-prensa/advertencias-y-medidas-administrativas-por-ejercicio-ilegal-de-actividad-financiera/advertencias-por-suplantacion--10097510"
               rel="noopener noreferrer"
               target="_blank"
               className="links"
             >
               aquí
             </a>

             <br></br>
             &copy;2022 PrestaPolis S.A.S. todos los derechos reservados{" "}
             {`${process.env.REACT_APP_VERSION}`}{" "}
             
             <br></br>
             {`${commerceData.nombre} de Colombia`}{" "}
             <a
               href="mailto:ayuda@prestapolis.com"
               rel="noopener noreferrer"
               target="_blank"
               className="links"
             >
             </a>{" "}
             {`NIT ${commerceData.nit} - ${commerceData.checkDigit}`}{" "}
             <a
               href="https://www.sic.gov.co/"
               rel="noopener noreferrer"
               target="_blank"
             >
               <br></br>
               <img
                 className="logo2"
                 alt="Logo"
                 id="industriaycomerio"
                 src={logoIC}
               />{" "}
             </a>

           </p>
         </div>
       </div>)}
        

      </div>
    </>
  );
}
