import React from 'react';

import PrestapolisPublic from './prestapolis';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from './app/config/themaConfig';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <PrestapolisPublic />
    </ThemeProvider>
  );
}

export default App;
