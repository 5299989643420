import { CollectionsOutlined } from '@material-ui/icons';
import React from 'react';


export const HelpValidate = () => {


    const limitLengthNumber = (register, max, min,  setError, clearErrors) => {

        let onChange = (e) => {
            
            e.target.value = writeNumber(e.target.value);
            e.target.value = e.target.value.substring(0,min);

            clearErrors();
            if(e.target.value.length < max) {
                setError(register.name, {
                    type: "manual",
                    message: `El tamaño minimo es de ${max}`,
                  });
            }
      
          

           

            return register.onChange;
        }


        return {
            ...register,
            onChange
        }

    }

    const limitLengthNumberAdd = (register,  max, min,  setError, clearErrors) => {
       
            let onChange = (e) => {
                
                e.target.value = writeNumber(e.target.value);
                e.target.value = e.target.value.substring(0,min);
    
                clearErrors();
               

                if(e.target.value.length < max) {
                    setError(register.name, {
                        type: "manual",
                        message: `El tamaño minimo es de ${max}`,
                      });
                }
    
              
    
               
    
                return register.onChange;
            }
    
    
            return {
                ...register,
                onChange
            }
    
        }

    const limitLengthText = (register) => {

        let onChange = (e) => {
          
            e.target.value = writeText(e.target.value);
           

            return register.onChange;
        }


        return {
            ...register,
            onChange
        }

    }

    const formatNumber = (register) =>{

        let onChange = (e) => {

            let value = e.target.value.toString().replaceAll(".","").replaceAll(",","");
            value = writeNumber(value);
            //e.target.value = new Intl.NumberFormat(("ban", "id")).format(e.target.value);
            value = new Intl.NumberFormat(("ban", "id")).format(value); 
          
            e.target.value = value;

            return register.onChange;

        }

        return {
            ...register,
            onChange,
            
        }

    }

    const setDescriptionValues = (register, setValue, seisValue)=> {

        let onChange = (e) => {

            const producto = e.target.value != "" ? true : false;
            seisValue(producto);
            setValue(e.target.value)
            return register.onChange;
        }
        
        return {
            ...register,
            onChange,
        }
    }


    const formatNumberAmount = (register, setbutton, setValueAbono, commerceData, setValorAbonoMinimo, setvalorProductoAft, refAbono, isCheck, setLogValueAbono ) =>{

        let onChange = (e) => {

            let value = e.target.value.toString().replaceAll(".","").replaceAll(",","");
            value = writeNumber(value);

            let valueAbono = ""
            let tipoForm = commerceData.tipoFormulario === undefined? "NO_TIPO": commerceData.tipoFormulario;
            

            if(!isCheck){
                if (tipoForm === "ABONO_MINIMO_OBLIGATORIO"){
                    
                    valueAbono =  value * (commerceData.baseDivisorAbono/100);
                    valueAbono = Math.round(valueAbono/100) * 100;
                    valueAbono = Math.trunc(valueAbono);
                    valueAbono = valueAbono.toString().replaceAll(".","").replaceAll(",","");
                    valueAbono = writeNumber(valueAbono);
                    setValorAbonoMinimo(valueAbono);
                    valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono); 
                    setValueAbono(valueAbono);
                    setbutton(false);
                    value = new Intl.NumberFormat(("ban", "id")).format(value); 
                    e.target.value = value;
                    setvalorProductoAft(value);
                    return
                    
                }else if(tipoForm === "ABONO_MINIMO_SUGERIDO"){
                    
                    valueAbono =  value * (commerceData.baseDivisorAbono/100);
                    valueAbono = Math.round(valueAbono/100) * 100;
                    valueAbono = Math.trunc(valueAbono);
                    valueAbono = valueAbono.toString().replaceAll(".","").replaceAll(",","");
                    valueAbono = writeNumber(valueAbono);
                    valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono); 
                    setLogValueAbono(valueAbono);
                    setValueAbono(valueAbono);
                    setbutton(false);
                    value = new Intl.NumberFormat(("ban", "id")).format(value); 
                    e.target.value = value;
                    setvalorProductoAft(value);
                    return

                }else if(tipoForm === "SIN_ABONO"){
                    valueAbono = 0;
                    value = new Intl.NumberFormat(("ban", "id")).format(value); 
                    e.target.value = value;
                    setbutton(false);
                    setValueAbono(valueAbono);
                    setvalorProductoAft(value);
                    return 
                }else{
                    valueAbono=0;
                }
                //e.target.value = new Intl.NumberFormat(("ban", "id")).format(e.target.value);
                value = new Intl.NumberFormat(("ban", "id")).format(value); 
                e.target.value = value;
                setbutton(false);
                setValueAbono(valueAbono);
                setvalorProductoAft(value);

            }else{
                valueAbono =  value * (commerceData.baseDivisorAbono/100)
                valueAbono = valueAbono.toString().replaceAll(".","").replaceAll(",","");
                valueAbono = writeNumber(valueAbono);
                valueAbono = new Intl.NumberFormat(("ban", "id")).format(valueAbono);
                value = new Intl.NumberFormat(("ban", "id")).format(value); 
                e.target.value = value;
                setbutton(false);
                setValueAbono("0");
                setLogValueAbono(valueAbono);
                setvalorProductoAft(value);
                return
            }
            return register.onChange;

            

        }

        // let onBlur = (e) => {
        
        //     return refAbono.current.focus();
        // }


        return {
            ...register,
            onChange,
            // onBlur,
            
        }

    }

    

    const formatNumberAbono = (register, setValueAbono, commerceData, valorAbonoMinimo, setError, clearErrors, setminAbono, setbutton)  =>{

        let onChange = (e) => {

            let tipoForm = commerceData.tipoFormulario === undefined? "NO_TIPO": commerceData.tipoFormulario;

            if (tipoForm === "ABONO_MINIMO_SUGERIDO"){
                let value = e.target.value.toString().replaceAll(".","").replaceAll(",","");
                value = writeNumber(value);
                
                value = new Intl.NumberFormat(("ban", "id")).format(value); 
                e.target.value = value;
                setValueAbono(value);
            }

            if (tipoForm === "ABONO_MINIMO_OBLIGATORIO"){
                let value = e.target.value.toString().replaceAll(".","").replaceAll(",","");
                value = writeNumber(value);
                
                if(value < valorAbonoMinimo){
                    setError(register.name, {
                        type: "manual",
                        message: `El valor minimo es ${valorAbonoMinimo}`,
                    });
                    setminAbono(false)
                }
                else{
                    setminAbono(true)
                    clearErrors();
                }
    
                value = new Intl.NumberFormat(("ban", "id")).format(value); 
                e.target.value = value;
                setValueAbono(value);
            }

            setbutton(false)

            return register.onChange;

            

        }


        return {
            ...register,
            onChange,
            
        }

    }



    const isDateMaxEdad = (register,  setError, clearErrors, message, daysNumber, setBirt ) =>{

        let onChange = (e) => {
            
            const  fecha1 = new Date(e.target.value); 
            const fecha2 = new Date();

            const difference= Math.abs(fecha2-fecha1);
            const days = difference/(1000 * 3600 * 24)

            clearErrors();
            if(Math.trunc(days) < daysNumber) {
                setError(register.name, {
                    type: "manual",
                    message: `${message}`,
                  });
            }else{
                clearErrors();
                setBirt(fecha1);
            }


            return register.onChange;
        }


        return {
            ...register,
            onChange,
            
        }

    }


    const isDateExp = (register,  setError, clearErrors, message, birthDate) =>{

        let onChange = (e) => {
            
            const  fecha1 = new Date(e.target.value); 
            const fecha2 = new Date();
            const fechaNa = new Date(birthDate);
           

            clearErrors();
            if(fecha1 > fecha2 || fecha1.getFullYear() < fechaNa.getFullYear()+18) {
                setError(register.name, {
                    type: "manual",
                    message: `${message}`,
                  });
            }else{
                clearErrors();
            }


            return register.onChange;
        }


        return {
            ...register,
            onChange,
            
        }

    } 

    const validateMultiSelect = (register, handleChange, filter) =>{  

        let onChange = (event) =>{
            if (event === null || event.length <=5) {
                filter(event);
            }
        }

        let onInputChange = (event) => {
            if (event === null || event.length <= 5 ) {
                handleChange(event)
            } else {
                alert("solo puedes agregar 5")
            }
        }

        return {
            ...register,
            onChange,
            onInputChange,
            
        }


    }
    
    const writeNumber = (text) => {
        if (!isNaN(text)) {
            return text;
        }
        return text.substring(0, text.length - 1);
        
    }

        
    const writeText = (text) => {
        
        if (isNaN(text)) {
            return text;
        }
        return text.substring(0, text.length - 1);
        
    }




    return {
        limitLengthNumber,
        limitLengthText,
        formatNumber,
        formatNumberAmount,
        formatNumberAbono,
        isDateMaxEdad,
        isDateExp,
        writeNumber,
        writeText,
        validateMultiSelect,
        setDescriptionValues,
        limitLengthNumberAdd
    };

}